<template>
    <div>
        <h3>Monthly Shipped Goals</h3>
        <button class="btn btn-default margin_bottom" @click="showModal = !showModal">Add Monthly Goal</button>
        <table class="table">
            <thead>
            <tr>
                <th>Month</th>
                <th>Year</th>
                <th>Goal</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(pastGoal, index) in state.object.data.goals" v-bind:key="index">
                <td>{{ pastGoal.month }}</td>
                <td>{{ pastGoal.year }}</td>
                <td>{{ pastGoal.goal}}</td>
            </tr>
            </tbody>
        </table>
      <ProductionGoalModal v-show="showModal" @close="closeModal"></ProductionGoalModal>
    </div>

</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';
    import ProductionGoalModal from './ProductionGoalModal';

    export default {
        name: 'MonthlyShippedGoals',
        components: {ProductionGoalModal},
        data() {
            return {
                state: store.state,
                showModal: false
            }
        },
        methods: {
            closeModal: function() {
                this.showModal = false;
            }
        }
    }

</script>