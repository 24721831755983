export const cdiLocationStore = {
    state: {
        name: 'cdi_location',
        title: 'CDI Location',
        titlePlural: 'CDI Locations',
        nav: {
            base: 'cdi_location'
        },
        search: {
            endPoint: 'cdi_location',
            formFields: {
                searchIndex: {
                    val: 0,
                    qsField: '[search_index]',
                }
            },
            resultFields: {
                cdi_location_id: { header: 'ID', link: 'cdi_location'},
                cdi_location: { header: 'Location', link: '' },
                cdi_street: { header: 'Street', link: '' },
                cdi_city: { header: 'City', link: '' },
                cdi_state: { header: 'State', link: '' },
                cdi_zip: { header: 'Zip', link: '' },
                cdi_phone: { header: 'Phone', link: '' },
                cdi_fax: { header: 'Fax', link: '' }
            }
        },
        object: {
            fields: [
                {
                    name: 'cdi_location_id',
                    label: 'CDI Location ID',
                    component: 'DetailsStatic'
                },
                {
                    name: 'cdi_location',
                    label: 'Name',
                    component: 'DetailsStatic'
                },
                {
                    name: 'cdi_street',
                    label: 'Street',
                    component: 'DetailsText'
                },
                {
                    name: 'cdi_city',
                    label: 'City',
                    component: 'DetailsText'
                },
                {
                    name: 'cdi_state',
                    label: 'State',
                    component: 'DetailsText'
                },
                {
                    name: 'cdi_zip',
                    label: 'Zip',
                    component: 'DetailsText'
                },
                {
                    name: 'cdi_phone',
                    label: 'Phone',
                    component: 'DetailsText'
                },
                {
                    name: 'cdi_fax',
                    label: 'Fax',
                    component: 'DetailsText'
                },
                {
                    name: 'cdi_min_build_days',
                    label: 'Standard Build Time',
                    component: 'DetailsText'
                },
                {
                    name: 'sales_scorecard_goal',
                    label: 'Annual Scorecard Goal',
                    component: 'DetailsText'
                },
                {
                    name: 'current_month_goal',
                    label: 'Current Month Scorecard Goal',
                    component: 'DetailsText'
                }
            ]
        }
    }
}