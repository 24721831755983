<template>
    <div>
        <h3>Automatic Routing</h3>
        <div v-if="routing">
            <div class="row">
                <div class="col-sm-6 col-xs-12 form-group">
                    <label>States</label>
                    <Typeahead cdi_type="state" @update:ID="addState"/>
                </div>
            </div>
            <ul class="cdi_button box_list">
                <li v-for="(locationState, index) in routing.states" v-bind:key="index">
                    {{ locationState.cdi_value }}
                </li>
            </ul>

            <div class="row">
                <div class="col-sm-6 col-xs-12 form-group"><label>Zipcodes</label><input v-model="zipField" type="text"
                                                                                         @keypress.enter="addZip"
                                                                                         class="form-control"></div>
            </div>
            <ul class="cdi_button box_list">
                <li v-for="(locationZip, index) in routing.zips" v-bind:key="index">{{
                        locationZip.zip
                    }} <span class="glyphicon glyphicon-remove" @click='deleteZipDialog(locationZip.zip)'></span>
                </li>
            </ul>
        </div>
        <ConfirmBox
            v-if="showConfirmBox"
            :heading="'Remove'"
            :body="'Remove zip from routing?'"
            @close="deleteZip"
        />
    </div>
</template>

<script>
import {store} from '@/store/BusinessObject.store.js';
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import ConfirmBox from "@/components/utils/ConfirmBox";

export default {
    name: 'Routing',
    components: {Typeahead, ConfirmBox},
    data() {
        return {
            state: store.state,
            zipField: '',
            showConfirmBox: false,
            selectedDeleteField: 0
        }
    },
    props: ['routing'],
    methods: {
        deleteZip(confirmed) {
            if (confirmed) {
                store.apiWithObjectLoad('delete_routing_zip', {params: this.selectedDeleteField});
            }
            this.showConfirmBox = false;
            this.selectedDeleteField = 0;
        },
        addZip() {
            store.apiWithObjectLoad('add_routing_zip', {params: this.zipField});
            this.zipField = '';
        },
        deleteZipDialog: function (zip) {
            this.selectedDeleteField = zip;
            this.showConfirmBox = true;
        },
        addState(state) {
            store.apiWithObjectLoad('add_routing_state', {params: state});
        },
    }
}

</script>