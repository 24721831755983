<template>
  <transition name="modal_fade">
    <div class="modal_backdrop">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">

        <div class="modal_header">
            Add Monthly Production Goal
          <button type="button" class="modal_btn_close" @click="close()" aria-label="Close modal"><span class="glyphicon glyphicon-remove"></span></button>
        </div>

        <div class="modal_body">
          <div class="form-group">
            <label>Month</label>
            <select class="form-control" v-model="formFields.month.val">
              <option value="0">None</option>
              <option v-for="[index, month] in helpers.date.getMonthsArray()" v-bind:key="index" :value="index">{{ month }}</option>
            </select>
          </div>
          <div class="form-group">
            <div class="form-group">
              <label>Year</label>
              <input type="text" class="form-control" v-model="formFields.year.val">
            </div>
            <div class="form-group">
              <label>Monthly Goal</label>
              <div class="input-group">
                <label class="input-group-addon">$</label>
                <input type="text" class="form-control" v-model="formFields.goal.val">
              </div>
            </div>
          </div>

        </div>

        <div class="modal_footer">
          <button type="button" class="btn btn-primary" @click="add()" aria-label="Close modal">Add</button>
          <button type="button" class="btn btn-default" @click="close()" aria-label="Close modal">Cancel</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { store } from '@/store/BusinessObject.store';
import DateHelpers from "@/store/DateHelpers";
import helpers from "@/store/helpers";

export default {
  name: "ProductionGoalModal",
  data() {
    return {
      helpers: {
        date: DateHelpers
      },
      formFields: {
        month: {val: 0, qsField: '[month]'},
        year: {val: new Date().getFullYear(), qsField: '[year]'},
        goal: {val: '', qsField: '[monthly_goal]'}
      }
    }
  },
  methods: {
    add: function() {
        store.apiWithObjectLoad('save_monthly_goal', helpers.getQSPairs(this.formFields));
        this.close();
    },
    close: function () {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>