<template>
    <div>
        <h3>Build Time Availability</h3>
        <div class="table-responsive">
        <table v-if="state.object.data.leadtimes" class="table">
            <thead>
            <tr>
                <th>Price Level</th>
                <th v-for="(buildTime, index) in state.object.data.leadtimes[0].build_times"  v-bind:key="index">{{ buildTime.label }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(priceLevel, index) in state.object.data.leadtimes" v-bind:key="index">
                <td>{{priceLevel.price_level}}</td>
                <td v-for="buildTime in priceLevel.build_times" v-bind:key="buildTime.build_time_id">
                    <input type="checkbox" v-model="buildTime.available" @click="updateLeadtimeAvailability(priceLevel.price_level_id, buildTime.build_time_id, !buildTime.available)" />
                </td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';

    export default {
        name: 'BuildTimeAvailability',
        data() {
            return {
                state: store.state,

            }
        },
        methods: {
            updateLeadtimeAvailability: function(price_level, build_time, value) {
                value = (value) ? 1 : 0;
                store.apiWithObjectLoad('update_leadtime_availability', { 'params[price_level_id]': price_level, 'params[build_time_id]': build_time, 'params[available]': value });
            }
        },

    }
</script>