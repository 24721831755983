<template>
    <div>
        <div v-if="id">
            <h1 v-if="state.object.data.cdi_location_id != 0">{{ state.object.data.cdi_location }}</h1>
            <Details :data='state.object.data' />
            <TimeAvailability />
            <MonthlyShippedGoals />
            <Routing :routing='state.object.data.routing' />
            <History :history="state.object.data.history" />
        </div>

        <Search v-else :hideFields='true' />
    </div>
</template>

<script>

    import { store } from '@/store/BusinessObject.store.js';
    import { cdiLocationStore } from './CdiLocation.store.js';
    import Search from '@/components/businessObjects/utils/Search';
    import Details from '@/components/businessObjects/utils/Details';
    import TimeAvailability from './TimeAvailability';
    import MonthlyShippedGoals from './MonthlyShippedGoals';
    import Routing from './Routing';
    import History from "@/components/businessObjects/utils/History";

    export default {
        name: "Container",
        data() {
            return {
                state: store.state,
                cdiLocationState: cdiLocationStore.state,
            }
        },
        props: ['id'],
        components: {
            Search,
            Details,
            History,
            TimeAvailability,
            MonthlyShippedGoals,
            Routing
        },
        created() {
            store.initialize(this.cdiLocationState);
            this.state.nav.hideUpload = true;
            this.load();
        },
        methods: {
            load: function() {
                if (this.id){
                    store.load(this.id)
                        .then(() => this.$appStore.setTitle([this.state.object.data.cdi_location, this.state.titlePlural]));
                }
            }
        },
        watch: {
            id: function (){
                this.load();
            }
        }
    }
</script>